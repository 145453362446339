import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Statistic from '../statistic';
import Library from '../library';
import AddOrder from '../add-order';

import './app.scss'

export default class App extends Component {

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/add-order" exact render={() => (
                        <AddOrder pageTitle={'Новый заказ'} />
                    )} />

                    <Route path="/" exact render={() => (
                        <AddOrder pageTitle={'Новый заказ'} />
                    )} />

                    <Route path="/statistic" exact render={() => (
                        <Statistic pageTitle={'Статистика'} />
                    )} />

                    <Route path="/library" exact render={() => (
                        <Library pageTitle={'Библиотека'} />
                    )} />
                </Switch>
            </Router>
        )
    }
}