import React, { Component } from "react";
import Spinner from "../../../helpers/spinner";
import moment from "moment";
import "./order-table.scss";
import logo from "./logo.png";
import phone from "./phone.png";
import adress from "./adress.png";
import calendar from "./calendar.png";

export default class OrderTable extends Component {
  state = {
    tableData: [],
    tablesHeaders: [],
    showSpinner: true,
    totalSumm: 0,
  };

  componentDidMount() {
    const { tablesHeaders, tableData } = this.props;
    this.setState({ tablesHeaders, tableData, showSpinner: false });
  }

  componentDidUpdate(prevProps) {
    const { tablesHeaders, tableData } = this.props;
    if (tableData !== prevProps.tableData) {
      this.setState({ tablesHeaders, tableData, showSpinner: true });
      setTimeout(() => {
        this.setState({ showSpinner: false });
      }, 500);
    }
  }

  renderTableHeaders = (items) => {
    const tableHead = items.map((item, i) => {
      const { th } = item;
      return <th key={i}> {th} </th>;
    });
    return tableHead;
  };

  renderNoData = () => {
    return <div className="no-data">В заказ пока ничего не было добавлено</div>;
  };

  renderTableData = (items) => {
    const { onDeleteItem } = this.props;

    const tableHead = items.map((item, i) => {
      const { productName, count, price } = item;
      const number = i + 1;
      return (
        <tr key={i}>
          <td> {number} </td>
          <td> {productName} </td>
          <td> {count} (шт.) </td>
          <td> {price} лей</td>
          <td> {Math.floor((price * count) * 100) / 100} лей</td>
          <td>
            <div className={"del-item"} onClick={() => onDeleteItem(i)}>
              Удалить
            </div>
          </td>
        </tr>
      );
    });

    return tableHead;
  };

  renderTable = (tablesHeaders, tableData, totalSumm) => {
    return (
      <div className={"print-container"}>
        <div className="info-container">
          <b><img src={calendar} alt="adress" className="phone" />График работы: Пн-Пт: 8:00 - 18:00 / Сб: 8:00 - 13:00</b>
          <b><img src={adress} alt="adress" className="phone" />Кишинёв, ул. Вадул луй Водэ 19, склад №6</b>
        </div>
        <div className={"company-container"}>
          <img src={logo} alt="Logo" />
          <b className="additional-font"><img src={phone} alt="phone" className="phone" />069 280 555</b>
          <b className="additional-font"><img src={phone} alt="phone" className="phone" />069 211 129</b>
        </div>
        <div className="clear" />
        <h2>Итого: {Math.floor(totalSumm * 100) / 100} лей</h2>
        <h5>Дата: {moment().format("DD.MM.YYYY HH:mm")}</h5>
        <table className="table table-hover">
          <thead>
            <tr>{this.renderTableHeaders(tablesHeaders)}</tr>
          </thead>
          <tbody>{this.renderTableData(tableData)}</tbody>
        </table>
      <div className="signature" />
      </div>
    );
  };

  checkTotalSumm = (data) => {
    let summ = 0;
    data.forEach((item) => {
      const { count, price } = item;
      summ += count * price;
    });
    return summ;
  };

  render() {
    const { tablesHeaders, tableData, showSpinner } = this.state;
    const totalSumm = this.checkTotalSumm(tableData);
    const content =
      tableData.length === 0
        ? this.renderNoData()
        : this.renderTable(tablesHeaders, tableData, totalSumm);
    return (
      <div className="table-responsive">
        {showSpinner && <Spinner />}
        {content}
      </div>
    );
  }
}
