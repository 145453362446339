import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import './footer.scss'

export default class Footer extends Component {

    render() {

        return (
            <footer className="footer">
                <div className="container-fluid">
                    <div className="copyright float-right">
                        &copy;
                        Powered by  <i className="material-icons"></i>
                        <Link to={'http://ssv-design.com'} target="_blank" className="nav-link">
                            http://ssv-design.com
                            </Link>
                    </div>
                </div>
            </footer>
        )
    }
}