import React, { Component } from 'react';
import axiosFetch from '../axios';
import Input from '../input';

import './editable-input.scss';

export default class EditInput extends Component {

    state = {
        editFieldId: 0,
        editFieldValue: '',
        editableFieldName: '',
        serverTable: '',
    };

    cancelAction = () => {
        this.setState({
            editFieldValue: '',
            editFieldId: 0,
            editableFieldName: '',
            serverTable: '',
        });
    }

    onEdit = (name, id, serverField, serverTable) => {
        const { action, onSuccessFunc } = this.props;
        const formData = new FormData();
        formData.append('value', name);
        formData.append('id', id);
        formData.append('serverField', serverField);
        formData.append('serverTable', serverTable);

        axiosFetch(action, formData).then(() => {
            this.cancelAction()
            onSuccessFunc();
        });
    }

    showEditFieldContainer = () => {
        const { editFieldValue, editFieldId, editableFieldName, serverTable } = this.state;
        return (
            <div>
                <Input colMd={'col-md-6'}
                    type={'text'}
                    onChange={(e) => { this.setState({ editFieldValue: e.target.value }) }}
                    value={editFieldValue}
                />
                <div className="col-md-4">
                    <i className="fa fa-floppy-o" onClick={() => { this.onEdit(editFieldValue, editFieldId, editableFieldName, serverTable) }}></i>
                    <i className="fa fa-remove" onClick={() => { this.cancelAction() }}></i>
                </div>
            </div>
        );
    }

    render() {
        const { name, id, serverField, serverTable } = this.props;
        const { editableFieldName, editFieldId } = this.state;

        const fieldState = editableFieldName === serverField && editFieldId === id ? this.showEditFieldContainer() : name;
        return (
            <div className="hover-edit-field">
                {fieldState}
                <i className="fa fa-edit" onClick={() => {
                    this.setState({
                        editFieldValue: name,
                        editFieldId: id,
                        editableFieldName: serverField,
                        serverTable: serverTable
                    })
                }}>
                </i>
            </div>
        );
    };
}