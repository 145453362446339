import React, { Component } from "react";
import Spinner from "../../../helpers/spinner";
import { EDIT_TABLE_FIELD } from "../../../helpers/constants"
import EditInput from "../../../helpers/editable-input";

import "./library-table.scss";

export default class LibraryTable extends Component {
  state = {
    tableData: [],
    tablesHeaders: [],
    showSpinner: true,
  };

  componentDidMount() {
    const { tablesHeaders, tableData } = this.props;
    this.setState({ tablesHeaders, tableData, showSpinner: false });
  }

  componentDidUpdate(prevProps) {
    const { tablesHeaders, tableData } = this.props;
    if (tableData !== prevProps.tableData) {
      this.setState({ tablesHeaders, tableData, showSpinner: true });
      setTimeout(() => {
        this.setState({ showSpinner: false });
      }, 500);
    }
  }

  renderTableHeaders = (items) => {
    const tableHead = items.map((item, i) => {
      const { th } = item;
      return <th key={i}> {th} </th>;
    });
    return tableHead;
  };

  renderNoData = () => {
    return <div className="no-data">Нет данных по вашему запросу</div>;
  };

  renderTableData = (items) => {
    const { onDeleteItem, reloadTable } = this.props;

    const tableHead = items.map((item, i) => {
      const { name, id } = item;
      return (
        <tr key={i}>
          <td>
            <EditInput
              name={name}
              id={id}
              serverField={"name"}
              serverTable={"products"}
              action={EDIT_TABLE_FIELD}
              onSuccessFunc={() => reloadTable()}
            />
          </td>
          <td>
            <div className={"del-item"} onClick={() => onDeleteItem(id)}>
              Удалить
            </div>
          </td>
        </tr>
      );
    });
    return tableHead;
  };

  renderTable = (tablesHeaders, tableData) => {
    return (
      <table className="table table-hover">
        <thead>
          <tr>{this.renderTableHeaders(tablesHeaders)}</tr>
        </thead>
        <tbody>{this.renderTableData(tableData)}</tbody>
      </table>
    );
  };

  render() {
    const { tablesHeaders, tableData, showSpinner } = this.state;

    const content =
      tableData.length === 0
        ? this.renderNoData()
        : this.renderTable(tablesHeaders, tableData);
    return (
      <div className="card-body">
        <div className="table-responsive">
          {showSpinner && <Spinner />}
          {content}
        </div>
      </div>
    );
  }
}
