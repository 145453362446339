import React, { Component } from 'react'
import SideBar from '../sidebar'
import Footer from '../footer'
import LibraryTable from './library-table'
import Input from '../../helpers/input'
import Button from '../../helpers/button'
import { ADD_PRODUCT, GET_PRODUCTS, REMOVE_PRODUCT } from '../../helpers/constants'
import axiosFetch from '../../helpers/axios';

import './library.scss'

export default class Dashboard extends Component {
    state = {
        productName: "",
        tablesHeaders: [
            { th: 'Наименование товара' },
            { th: 'Действия' },
        ],
        tableData: [],
        productsArray: [],
    }

    componentDidMount() {
        this.getProducts()
    }

    getProducts = () => {
        axiosFetch(GET_PRODUCTS).then((productsArray) => {
            this.setState({ productsArray, productName: "" });
        });
    }

    changeFieldsState = (field, inputValue) => {
        this.setState({ [field]: inputValue });
    }

    addProduct = (productName) => {
        const fd = new FormData();
        fd.append("productName", productName);

        axiosFetch(ADD_PRODUCT, fd).then(() => {
            this.getProducts()
        });
    }

    onDeleteItem = id => {
        const fd = new FormData();
        fd.append("id", id);

        axiosFetch(REMOVE_PRODUCT, fd).then(() => {
            this.getProducts()
        });
    }

    render() {
        const { pageTitle } = this.props
        const { tablesHeaders, productsArray, productName } = this.state
        return (
            <div className="wrapper ">
                <SideBar activeMenuItem={pageTitle} />
                <div className="main-panel">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="col-md-12 date-container">
                                <div className="card card-stats row">
                                    <div className="col-md-4">
                                        <label htmlFor="productName">Наименование</label>
                                        <Input className={"form-control"}
                                            type={"text"}
                                            id={"productName"}
                                            value={productName}
                                            onChange={(e) => this.changeFieldsState("productName", e.target.value)}
                                        />
                                    </div>

                                    <Button type={'button'}
                                        text={'Добавить товар в библиотеку'}
                                        className={'btn btn-success '}
                                        disabled={!productName}
                                        onClick={() => this.addProduct(productName)} />
                                </div>
                            </div>

                            <LibraryTable tablesHeaders={tablesHeaders}
                                tableData={productsArray}
                                onDeleteItem={this.onDeleteItem}
                                reloadTable={this.getProducts} />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}