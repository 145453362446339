import React, { Component } from 'react';

import './spinner.css'

export default class Spinner extends Component {

    render(){

        return(
            <div className="spinner">
              <div className="loadingio-spinner-double-ring-k6ez71mlp3"><div className="ldio-vcys1rikotc">
<div></div>
<div></div>
<div><div></div></div>
<div><div></div></div>
</div></div>
            </div>
        )
    };
}